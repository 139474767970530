
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import router from "@/router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
// import {AllBots} from "@/store/Pinia/AllBots";
// import {platforms} from "@/store/Pinia/Platforms";
// import {allPackages} from "@/store/Pinia/AllPackages";

export default defineComponent({
  name: "buy-package-stepper",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "new",
    },
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const route = useRoute();

    watch(props, () => {
      if (props.bot) {
        formData.value.bot = props.bot;
        formData.value.name = props.bot.name;
      }
    });

    const store = useStore();

    // const platforms = ref();
    // const platformsHolder = platforms();
    // const { platformsList } = storeToRefs(platformsHolder);

    // const packageListHolder = allPackages();
    // const { packageList } = storeToRefs(packageListHolder);
const loading = ref(true);
    const packageList = ref();
    const get_packages = () => {
        loading.value = true;
      ApiService.get("/packages?type=crawler")
        .then((data) => {
          loading.value = false;
          console.log(data);
          
          packageList.value = data.data.data.packages;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const formData = ref({
      name: "",
      package: "" as any,
      package_id: "",
      credit: 0,
      bot: "" as any,
      discountCode: "",
      discountPrice: 0,
      appliedDiscountCode: undefined,
      discountStatus: "" as any,
      prices: 0,
      payablePrice: 0,
    });

    // watchEffect(() => {
    //   formData.value.package = packageList.value
    //       ? packageList.value[0]
    //       : "";
    //   formData.value.package_id = packageList.value
    //       ? packageList.value[0].id
    //       : "";
    // });

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("name"),
      package: Yup.string().required().label("package"),
    });

    // const AllBotHolder = AllBots();
    // const { fetchBotList } = AllBotHolder;

    const saveBot = async () => {
      try {
        const result = await ApiService.post("/crawler/bot/create", {
          name: formData.value.name,
        });
        return result.data.data.bot;
      } catch ({ response }) {
        // if(response && response.data) store.commit(Mutations.SET_ERROR, response.data);
        if (submitPayWithCredit.value)
          submitPayWithCredit.value?.removeAttribute("data-kt-indicator");
        if (submitPayOnline.value)
          submitPayOnline.value?.removeAttribute("data-kt-indicator");
      }
    };

    const sendParameter = computed(() => {
      return {
        bot_id: formData.value.bot?.bot_id,
        package_id: formData.value.package.id,
        discount_code:
          formData.value.appliedDiscountCode === true &&
          formData.value.discountCode
            ? formData.value.discountCode
            : undefined,
      };
    });

    const submitPayWithCredit = ref<HTMLElement | null>(null);
    const chargeCredit = async () => {

      // پرداخت آنلاین از درگاه
      submitPayWithCredit.value?.setAttribute("data-kt-indicator", "on");

      if (!formData.value.bot) formData.value.bot = await saveBot();
      if (!formData.value.bot) return;

      try {
        const result = await ApiService.post( 
          "user/credit/charge",
          {amount :  formData.value.payablePrice},
          // sendParameter.value
        );
        // await fetchBotList();
        window.location.href = result.data.data.port_url;
      } catch (e) {
        // if (e && e.response && e.response.data) store.commit(Mutations.SET_ERROR, e.response.data);
      } finally {
        submitPayWithCredit.value?.removeAttribute("data-kt-indicator");
      }
    };

    const submitPayOnline = ref<HTMLElement | null>(null);
    const payWithCredit = async () => {

      // پرداخت با کیف پول
      submitPayOnline.value?.setAttribute("data-kt-indicator", "on");
      if (!formData.value.bot) formData.value.bot = await saveBot();
      if (!formData.value.bot) return;

      try {
        const result = await ApiService.post("crawler/bot/buy", sendParameter.value);
        // await fetchBotList();
        formData.value.bot = result.data.data.bot;
        router.push(`/accounts/connect/${formData.value.bot?.bot_id}`);
      } catch ({ response }) {
        // store.commit(Mutations.SET_ERROR, response.data);
      } finally {
        submitPayOnline.value?.removeAttribute("data-kt-indicator");
      }
    };

    const loadingPrice = ref(false);

    const calculatePrice = () => {
      if (!formData.value.package) {
        formData.value.payablePrice = 0;
        formData.value.prices = 0;
        formData.value.discountPrice = 0;
        formData.value.credit = 0;
        return false;
      }

      let source = null as any;
      if (source) source.cancel("Operation canceled by the user.");
      source = axios.CancelToken.source();

      loadingPrice.value = true;
      ApiService.query(`/crawler/bot/calculate-price`, {
        params: {
          package_id: formData.value.package?.id,
          discount_code:
            formData.value.appliedDiscountCode === true &&
            formData.value.discountCode
              ? formData.value.discountCode
              : undefined,
        },
      })
        .then(({ data }) => {
          // formData.value.discountStatus = formData.value.discountCode
          //   ? true
          //   : undefined;

          formData.value.payablePrice = data.data.price.payablePrice;
          formData.value.prices = data.data.price.basePrice;
          formData.value.discountPrice = data.data.price.discountPrice;
          formData.value.credit = data.data.price.userCredit;

          if (Number(data.data.price.discountPrice) > 0) {
            formData.value.discountStatus = true;
          } else {
            formData.value.appliedDiscountCode = undefined;
          }
          loadingPrice.value = false;
        })
        .catch((e) => {
          if (e.response?.data)
            store.commit(Mutations.SET_ERROR, e.response?.data);
          if (formData.value.discountCode) {
            formData.value.discountStatus = "false";
            formData.value.appliedDiscountCode = undefined;
          }
          if (axios.isCancel(e)) {
            console.log("Request canceled", e.message);
          } else {
            loadingPrice.value = false;
          }
        });
    };

    onMounted(() => {
      get_packages();
      if (route.name === "buy")
        setCurrentPageBreadcrumbs("خرید اشتراک", ["اشتراک های من"]);
    });

    return {
      translate,
      calculatePrice,

      formData,
      route,
      // platformsList,
      packageList,

      loadingPrice,
      chargeCredit,
      payWithCredit,

      submitPayWithCredit,
      submitPayOnline,
      validationSchema,
      sendParameter,
      loading,
    };
  },
});
