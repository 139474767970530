import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fa757fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-wrap" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center symbol symbol-circle symbol-35px border p-1 opacity-50" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platformsList, (pl, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (!_ctx.botplatforms.includes(pl.type))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "d-flex text-nowrap btn btn-sm btn-outline btn-outline-dashed btn-active-light me-1 mb-1 d-flex align-items-center p-1",
                to: `/bank/connect/${_ctx.botId}?platform=${pl.type}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                      src: pl.logo,
                      alt: pl.name
                    }, null, 8, _hoisted_3)
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}