
import { defineComponent, ref, onMounted } from "vue";
// import { platforms } from "@/store/Pinia/Platforms";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "kt-not-connected-platforms",
  props: {
    botplatforms: { type: [], default: [], required: true },
    botId: { type: String },
    platformsList: [],
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    // const platformsHolder = platforms();
    // const { platformsList } = storeToRefs(platformsHolder);

    return {
      //   platformsList,
      translate,
    };
  },
});
