
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { translate, statusColor, statusIcon } from "@/core/services/HelpersFun";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { platforms } from "@/store/pinia/Platforms";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";

// new
import NotConnectedPlatform from "./components/NotConnectedPlatform.vue";
// import NotConnectedPlatform from "./components/BankNotConnectedPlatform.vue";
import BuyNew from "./components/buyNew.vue";
import CreateRequest from "./components/CreateRequestForm.vue";

export default defineComponent({
  name: "kt-otp-management-table",
  components: {
    NotConnectedPlatform,
    BuyNew,
    CreateRequest,
  },
  props: {
    widgetClasses: String,
  },

  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      // return t(text);
      if (te(text)) return t(text);
      return text;
    };

    const req_modal = ref(false);
    
    const AllBotsHolder = AllBots();
    const { crowlerBotList } = storeToRefs(AllBotsHolder);
    const { fetchCrowlerBotList } = AllBotsHolder;

    const platformsHolder = platforms();
    const { crowlerPlatformsList } = storeToRefs(platformsHolder);
    const { fetchCrowlerPlatforms } = platformsHolder;

    

    const activate_platform = async (botId, plat) => {
      await ApiService.query(`crawler/bot/${botId}/${plat}/activate`, {})
        .then(({ data }) => {
          crowlerPlatformsList.value = data.data.crawler_platforms;
          console.log(crowlerPlatformsList.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const router = useRouter();
    const search = ref();
    const editBotInfo = ref({
      bot_id: "",
      name: "",
    });

    const searchItems = () => {
      // search items
    };

    const checkBots = () => {
      if (crowlerBotList.value) {
        var countAll = 0;
        let count = crowlerBotList.value.map((bot) => {
          if (bot.platforms) {
            return (countAll = bot.platforms.filter(
              (item) => item.status === "ready"
            ).length);
          }
        });

        if (count.length === 0) {
          Swal.fire({
            html: `برای استخراج شماره تلفن، ابتدا باید از طریق
            <strong class="text-primary">دکمه ایجاد اشتراک</strong>
            ، یک اشتراک ایجاد کنید`,
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "بله، متوجه شدم",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else if (count.length > 0) {
          var countPlat = count.reduce((a, b) => a + b);
          if (countPlat > 0) {
            router.push("/requests");
          } else {
            Swal.fire({
              html: `برای استخراج شماره تلفن، باید پلتفرم ها را به اشتراک خود متصل کنید. برای اینکار از
 <strong class="text-primary">دکمه مدیریت پلتفرم ها</strong>
در این صفحه استفاده کنید.`,
              icon: "info",
              buttonsStyling: false,
              confirmButtonText: "بله، متوجه شدم",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        }
      }
    };

    const connectedPlatforms = (plat) => {
      if (!plat) return [];
      return plat.map((item) => {
        return item.platform;
      });
    };

    onMounted(() => {
      // get_bots();
      fetchCrowlerBotList()
      fetchCrowlerPlatforms()
      // get_platforms();
      // setCurrentPageTitle("اشتراک های من");
    });

    return {
      req_modal,
      crowlerBotList,
      statusIcon,
      statusColor,
      translate,
      // deleteBotFunc,
      search,
      searchItems,
      editBotInfo,
      crowlerPlatformsList,
      // disconnect,
      checkBots,
      // fetchBotList,
      connectedPlatforms,
      activate_platform,
    };
  },
});
